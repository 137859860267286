import React from 'react';
import Layout from "../components/layout";
import { Link } from "gatsby";

const Sitemap = ({ pageContext }) => {
    const pageTitle = `${pageContext.kernel_name} - Sitemap`;
    return (
        <Layout pageTitle={pageTitle} menuList={[]} sitemap={false}>
            <div className={'col-sm-12'}>
                <h1 style={{ marginTop: '30px' }}>{pageTitle}</h1>
            </div>
            <div className='row'>
                {pageContext.urlArr.map(elem => (
                    <div key={Date.now() + Math.random()} className='col-md-12'>
                        <Link to={`/${elem}`}>{`/${elem}`}</Link>
                    </div>
                ))}
            </div>
        </Layout>
    );
};

export default Sitemap;